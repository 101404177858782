import { createAnalytics, type Analytics } from '@seek/seek-jobs-analytics';
import { type ReactNode, createContext, useContext } from 'react';

import type { ClientContext } from 'src/types';

export const createAnalyticsHelper = ({
  brand,
  environment,
}: Pick<ClientContext, 'brand' | 'environment'>): Analytics =>
  createAnalytics(
    {
      environment: environment === 'production' ? 'prod' : 'dev',
      profile: 'candidate-main',
    },
    {
      brand,
      currentPage: 'Global Landing Page',
      // Since we are not able to determine the state, so it will be always FALSE
      isLoggedIn: false,
      siteCountry: 'ZZ',
      siteLanguage: 'en',
      siteSection: 'discover',
      zone: '',
    },
  );

const initialState: Analytics = createAnalytics({
  environment: 'test',
});

const AnalyticsContext = createContext<Analytics>(initialState);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({
  children,
  analytics,
}: {
  children?: ReactNode;
  analytics: Analytics;
}) => (
  <AnalyticsContext.Provider value={analytics}>
    {children}
  </AnalyticsContext.Provider>
);
